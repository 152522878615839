<template>
  <div id="Footer">
    <div class="footer_num">
      <span></span>
      <a href="https://beian.miit.gov.cn">ICP认证:沪ICP备2022028904号&nbsp; </a>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009880">沪公网安备31010402009880号&nbsp; </a>
      <span> {{ $t(`message.Footer.address`) }}</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import url('../style/Footer.less');
</style>
