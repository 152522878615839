import enLocale from 'element-ui/lib/locale/lang/en'; //引入element语言包
//中文对应的英文
const en = {
  message: {
    info: {
      userinfo: 'userinfo',
    },
    table: {
      item_number: 'item number',
    },
    Home: {
      //未登录
      home: 'home',
      Title: 'Hello, please sign in',
      emailLogin: 'Account password login',
      phoneLogin: 'Mobile number login',
      forgetPwd: 'Forgot Password?', //忘记密码
      autoLogin: 'Auto login within 15 days', //十五天
      login: 'Log in', //登录
      register: 'Register now', //注册
      Cantcode: 'Can’t receive verify code？', //接收不到验证码
      //输入框提示信息
      Address: 'Email Address', //请输入邮箱
      Password: 'Password', //请输入面貌
      mobile: 'mobile number', //手机号
      Resend: 'Resend', //倒计时
      verify: 'verify code', //验证码
      Verification: 'Verification code', //发送验证码

      //登录

      //滑块验证
      Security: 'Security verification', //请先完成滑块验证
      slider: 'Drag the left slider to verify',
      //错误提示
      emailError: 'Invalid email address. Try again.',
      passwordError: 'Invalid password. Try again.',
      passwwordNot: 'The email address you entered is not assigned to a registered user. Try again.',
      //注册
      Register: 'Register Now',
      QR: 'How to register? Scan the QR code and contact us',
      //联系方式
      Contact: 'Contact us',
      QRcode: 'Scan the QR code for more information.',
      //  找回密码
      retrieve: {
        // 第一页
        ConfirmAccount: 'Confirm Account',
        Return: 'Return to the home page',
        EnterYour: 'Enter your email account to retrieve your password',
        EmailAccount: 'Your Email Account',
        TryAgain: 'Invalid email address. Try again.',
        NextStep: 'Next Step',
        // 第二页
        Verification: 'Verification',
        SentTo: 'An email with verification will be sent to ',
        verifycode: 'verify code',
        Sendcode: 'Send code',
        Back: 'Back',
        ContactUs: 'Cannot verify? Please contact us for help',
        ErrorVerify: 'Invalid code. Try again.',

        //第三页
        NewPwd: 'New password',
        setNew: 'Please set a new password for',
        ErrorTip: 'Password does not meet minimum requirements.',
        ErrorTips: 'Confirm password.',
      },
    },
    Header: {
      one: 'Home',
      two: 'My Project',
      three: 'Performance Prediction',
      four: 'Category Analysis',
      five: 'Historical Data Comparison',
      six: 'Help',

      //二级菜单
      //单品分析
      single: 'Single Product Analysis',
      compare: 'Comparative Analysis',
      //品类分析:
      profile: 'Sensory Profile',
      classification: 'Cluster Analysis',
      Feature: 'Feature Analysis',
      significance: 'Importance Analysis',
      //修改密码
      ResetPassword: 'Reset Password',
      OldPassword: ' Old Password: ',
      NewPassword: 'New Password: ',
      Confirmpassword: 'Confirm password: ',
      EnterOldPWD: 'Enter old password',
      EnterNewPWD: 'Enter new password',
      EnterNewPWDs: 'Confirm password',
      passwordincorrect: 'Old password incorrect.',
      Passwordmust: 'Password must include letter, number and special character  ',
      Passwordsmatch: 'Passwords do not match.',
      Change: 'Change Password',
      Switch: 'Switch Account',
      Log: ' Log out',
    },
    Project: {
      //一级导航栏
      one: 'My Project',
      two: 'New Project',
      three: 'Existing Project',
      four: 'Draft',
      //修改初始密码
      Reset: 'Reset Password',
      safety: 'For safety, please reset your password.',
      New: 'New Password: ',
      Confirm: 'Confirm password: ',
      Enter: 'Enter password',
      Confirms: 'Confirm password',
      successfully: 'Password changed successfully',

      // ReturnTo:"Return to login page in",
      // ReturnTos:"seconds",

      ////项目首页-------------
      Fast: 'Existing Project', //快速访问
      recently: 'Latest Application', //最近
      recentlyOpne: 'Recent', //最近打开
      number: 'Project No.', //项目编号
      CreationTime: 'Date Created', //创建时间
      QuantityProduct: 'Number', //产品数量
      ProductName: 'Product Name', //产品名称
      Operation: 'Actions', //操作
      empty: 'No Recent Projects', //空数据

      //项目操作项------------
      view: 'Check',
      edit: 'Edit',
      forecast: 'Predict',
      derive: 'Export',
      remove: 'Remove',
      delete: 'Delete',

      Cancel: 'Cancel',
      OK: 'OK',
      Save: 'Save',
      Remove: 'Are you sure you want to remove the project from the list?',
      Removeit: 'Remove it from “Latest Application”',
      SaveAs: 'Save As',
      Reports: 'Are you sure you want to re-upload the file?',
      ReDetail: 'This will overwrite instead of append.',
      AsProjectNo: 'Save As Project No.',
      onlyOne: 'Please select only 1 product for copy.',
      SaveAS: 'Save As successfully!',
      NotCopy: 'No-editing mode cannot be copied',
      NotAdd: 'Products cannot be added without editing',
      //新建/修改下拉选择
      None: 'None',
      Market: 'In-market Product',
      Prototype: 'Prototype',
      Benchmark: 'Benchmark',
      Select2: 'Select',
      //--产品性状
      Cream: 'Cream',
      Lotion: 'Lotion',
      Gel: 'Gel',
      Oil: 'Oil',
      Other: 'Other',
      Smearmask: 'Smear mask',
      Sheetmask: 'Sheet mask',
      Toner: 'Toner',
      Select: 'Select',
      //--物理化学防晒
      Chemical: 'Chemical',
      Physical: 'Physical',
      Hybird: 'Hybird',
      Select3: 'Select',
      // 新建项目----------------------
      new: {
        SaveD: 'Product(s) successfully saved!  ',
        informationError: 'Incorrect product information, please edit. ',
        NewProject: 'New Project',
        Choosefile: 'Choose a file or drag it here.',
        downLoad: 'For the first time, please download the template.',
        Import: 'Import',
        Cancel: 'Cancel',
        Edit: 'Edit',
        Importhint: 'Import a new file to overwrite',
        Save: 'Save',
        Category: 'Category',
        Suncare: 'Sun care', //防晒霜
        Copy: 'Copy',
        Delete: 'Delete',
        Loaded: 'Imported',
        SensoryData: 'Sensory Data',
        InstrumentalData: 'Instrumental Data',
        ComingSoon: 'Coming Soon',
        CancelEdit: 'Cancel Edit', //取消编辑
        Editing: 'Editing', //编辑中
        Create: 'Finish', //完成
        Null: 'Null',
        Unselected: 'Unselected',
        auto: 'Draft Auto-Saved',
        Saving: 'Saving… ',
        Saved: 'Draft Auto-Saved  ',
        DeleteThis: 'Are you sure you want to delete this product?',
        ProjectNo: 'Project No.',
        ImportTip: 'Import a new file to overwrite', //导入
        router: 'Do you want to jump to another page?',
        routerContent: 'Your product information is being edited',
      },
      check: {
        Download: 'Download',
      },
      //已有项目------------
      HaveTitle: 'Existing Project', //已有项目

      //已有项目和草稿箱的搜索翻译
      Item: 'Project No.: ', //项目编号
      information: 'Product Info: ', //产品信息
      CreationTimes: 'Date: ', //创建时间
      allExport: 'Export', //多选导出
      allDelete: 'Delete', //多选删除
      start: 'Start',
      To: 'To',
      end: 'End',
      Export: 'Export', //批量导出
      Delete: 'Delete', //批量删除
      ActionUndone: 'The action cannot be undone.',
      //表头信息---------------
      item: 'Project No.', //项目编号
      creationTimes: 'Date Created', //创建时间
      category: 'Category', //品类
      quantity: 'Number', //产品数量
      productName: 'Product Name', //产品名称
      operation: 'Actions', //操作
      //草稿箱-----------
      draftTitle: 'Draft', //草稿箱
      editing: 'Continue', //草稿箱的项目操作
      emptyDraft: 'No Draft Yet',
      NoResults: 'No Results Found', //搜索为空
      NoProjects: 'No Projects Yet',
      //删除同上
      NotSaved: 'Your product has not been saved!',
      PleaseSave: 'Please save your changes before you leave this page.',
      // 页面展示
      selected: 'projects selected', //已选择
      total: 'in total', //共
      found: 'projects found', //搜索到
    },
    itemAnalyse: {
      //标题和选择框
      Title: 'Single Product Analysis',
      //Select
      item: 'Project',
      products: 'Product',
      population: 'Target Consumer',
      select: 'Select', //点击下拉选择
      select1: 'Select',
      select2: 'Select',
      //感官属性box1----
      box1Title: 'Sensory Attributes', //感官属性
      Importance: 'Importance', //重要度
      inputContent: 'Enter or Select Attributes',
      Sensorydata: 'Sensory data',
      //content
      all: 'All',
      Sensory: 'Sensory data', //感官数据
      //消费者指标预测 box2------
      OverallAttributes: 'Overall Attributes',
      SpecifiaAttributes: 'Feature Attributes ',

      box2Title: 'Al Prediction', //消费者指标预测
      explain: 'Prediction via Sensory', //通过感官数据测量
      section95: '95%CI: ', //95置信区间
      // section90:"90%CI:",//90置信区间
      section90: '5-point scale',
      Predicted: 'Prediction of OAL', //整体喜好度预测值
      percentage: 'Accuracy(%)', //预测精准度百分比

      Overall: 'Overall Liking (OAL)', //整体喜好度
      Comprehensive: 'Overall Attributes', //综合性指标
      Preference: 'Specific Attributes', //喜好度指标
      predicted: 'Hedonic', //喜好度
      null: 'None', //暂无
      accuracy: 'Accuracy', //精准度
      barChat: 'Histogram', //柱状图
      Rainbow: 'Rainbow Diagram', //彩虹图
      //选择对比产品 box3-------
      box3Title: 'Choose Products for Comparison', //选择对比产品
      maximum: '', //选择上限
      Recommended: 'Optimal Solution', //推荐优化方案
      add: 'Add to the project', //添加到项目
      Norecommended: 'No optimal solution available', //暂无推荐方案(空)
      product: 'product', //产品
      liking: 'OAL', //整体喜好度
      Appeara: 'Appeara...', //产品外观
      Overallus: 'Overall us ...', //使用后的肤感
      Absorpti: 'Absorpti...', //产品质地
      Successfully: 'Successfully Added',
      Historical: 'Historical Products', //提示产品选择
      createTime: 'Date Created', //创建日期
      number: 'Project No.', //项目编号
      name: 'Product Name ', //产品名称
      ocerallPredicted: 'Overall Liking', //整体喜好度
      NoHistorical: 'No historical products available', //暂无历史产品(空)
      optimal: 'optimal',
      //彩虹图的信息框
      productName: 'Product: ',
      Values: 'Value: ',
      TargetConsumers: 'Target Consumer: ',
      // 柱状图信息
      konw: 'More',
      Population: 'The accuracy of the model for target consumer is decrased , and the predicted results are for reference only.', //!目标人群
      ProductNume: 'Product Name',
      Note: 'Note',
      analyse: 'Please analyze it first', //!请您先进行分析
      noadd: 'Please do not add to the project again', //! 请勿重复添加
      nohandle: 'Please do not repeat the operation', //!请勿重复操作
      cilckProject: 'Click on the recommended product that will be selected', //!点击将选择的推荐产品
      cilckProject2: 'Add to the current project', //!添加到当前项目中
      manyProduct: 'To select the Product, cancel one or more check boxes', //!对比产品已达选择上限，请取消单个或多个选框重新选择
    },
    Contrast: {
      Title: 'Comparative Analysis', //对比分析
      //Select
      item: 'Project',
      products: 'Products',
      compare: 'Select Products',
      select: 'Select',
      select1: 'Select',
      select2: 'Select',
      //content---box1---------
      box1Title: 'Sensory Data', //感官属性数据
      Importance: 'Importance', //重要度
      AttributeName: 'Attribute', //  属性名称
      AttributeData: 'Value', //  属性数据

      NoAttribute: 'Sensory Data Not Available', //暂无属性数据(空)

      //box2----
      box2Title: 'Result', //对比分析结果
      Difference: 'Difference', //差值
      NoResult: 'Result Not Available', //暂无分析结果
    },
    Category: {
      // 共同
      common: {
        Category: 'Category',
        Products: 'Products',
        Hide: 'Hide',
        Show: 'Show',
        Description: 'Description',
        empty: 'No Graph Available',
        DownloadGraph: 'Current Graph', //单张下载
        DownloadAll: 'All Graph', //全部下载
        Select: 'Select',
      },
      // 产品轮廓-----------------------start
      page1: {
        Title: 'Sensory Profile',

        // content

        empty: 'No Graph Available',
        Range: 'Product',
      },
      // 产品轮廓-----------------------start
      page2: {
        Title: 'Cluster Analysis',

        // content
        Label: 'Label',
        SPF: 'SPF Value',
        none: 'None',
        Formulation: 'Formulation System',
        Physical: 'Physical',
        Chemical: 'Chemical',
        Hybrid: 'Hybrid',
        empty: 'No Graph Available',
      },

      // 特征分析-----------------------start
      page3: {
        Title: 'Feature Analysis',
        Factor: 'By Factor',

        Select: 'Select',
        PCA: 'PCA',
        Rotated: 'Rotated',
        Clustering: 'Clustering',
        SPFValues: 'SPF Values',
        FormulationSystem: 'Formulation System',
        None: 'None',
        Clusters4: '4 Clusters',
        Clusters6: '6 Clusters',
        Clusters8: '8 Clusters',
        Clusters12: '12 Clusters',
        Legend: 'Legend', //图例显示"
        detail: 'After leaving, the current analysis results will not be retained.',
      },

      // 重要度分析-----------------------start
      page4: {
        title: 'Importance Analysis', //标题
        Axis: 'Axis', //坐标
        Select: 'Select',
        Selects: 'Select',
        SelectOne: 'Select',
        Attributes: 'Attributes', //坐标选择
        ChartType: 'Chart Type', //图表类型
        HeatMap: 'Heat Map', //热力图
        Network: 'Network', //网络图
      },
    },
    History: {
      Select: 'Select', //项目
      Select1: 'Select', //点击下拉选择
      Category: 'Category', //品类
      Products: 'Select Products', //产品
      Sensory: 'Sensory Results',
      Predicted: 'Predicted Results',
      Scale: 'Scale', //显示刻度
      Percentile: 'Percentile Lines', //百分位线
      empty: 'No Graph Available', //暂时还没有图表哦
      limit: 'Upper limit: 30 products  ',
      limits: 'Upper limit: 8 products  ',
      Comparison: 'Please choose product(s) from comparison.  ',
      NoData: 'No Data Available',
      Min: 'The minimum number is 3!',
      SensoryBar: {},
    },
    Help: {
      Title: 'Help',
      Contact: 'Contact us',
      Question: 'Question',
      Lines: 'Percentile Lines',
      BackSee: "If you don't see your question, click here!",
      Name: 'Your Name',
      Company: 'Company',
      YourEmail: 'Your Email',
      Questions: 'Question',
      detail: 'Please write your questions or description of the problems here',
      Send: 'Send',
      Names: 'Name',
      Companys: 'Companys',
      Email: 'Email',
      Question2:
        'Due to round up/down, sometimes the number is the same, but the bar length is different. It is not a sign of malfunction, please feel free to use.',
    },
    Message: {
      unfinished: 'In the analysis, please wait a minute',
      inished: 'The rotation analysis is complete',
      NoSelected: ' No optimal solution selected!',
      phoneNumber: 'Invalid phone number. Try again! ', //手机号不正确，请重新输入；
      Verification: 'Verification code not sending!', //验证码未发送，请点击发送
      accountNot: 'The account is not registered, register first!', //手机号码未注册，请先注册！
      codeError: 'Invalid code. Try again.', //验证码不正确，请重新输入
      notRegistered: 'The account is not registered!', //该账号未注册
      Login: 'Login successful', //登录成功
      Saved: 'Saved successfully', //保存成功
      Oldpassword: 'Old password incorrect', //旧密码错误
      export: 'Please select more than 1 product for batch export', //请选择一条以上的产品信息进行批量导出
      delete: 'Please select more than 1 product for batch delete', //请选择一条以上的产品信息进行批量删除
      Passwordmust: 'Password must include letter, number and special ', //输入密码要包含英文字母,数字,字符
      Passwords: 'Passwords do not match', ////两次输入密码不相同
      Import: 'Import successful!', //导入成功!
      ProjectNo: 'This Project No. already exists, please change! ', //项目名重复,请修改后在保存
      Atleast: 'Please select at least 1 product to delete  ', //请选择勾选至少一条产品信息进行删除操作,
      emptyNo: 'Project No. cannot be empty or already exists, please change', //项目编号不可为空或以重复,请修改后再次上传
      emptyNo1: 'Product categories in the project are inconsistent, please modify and upload again', //项目内产品品类不一致，请修改后再次上传
      Batchoperation: 'Please select at least 1 project for batch operation', //请选择至少一条以上的项目进行批量操作
      notSave: 'Your product has not been saved!', //您的产品信息还未保存
      leaveSave: 'Please save your changes before you leave this', //返回时请保证产品信息处于保存状态
      Missing: 'Missing information!', //您还有未选择的信息
      FIELDS: 'Fields marked with an * are required ', //带*号为必填
      confirmDelete: 'Are you sure you want to delete “”? ', //确定删除*** 项目吗？
      undone: 'The action cannot be undone', //删除后将无法恢复
      re_upload: 'Are you sure you want to re-upload the file?', //您确定重新上传项目文件？
      overRite: 'This will overwrite instead of append', //重新上传后，该项目当前数据将被上传覆盖
      confirmDeletes: 'Are you sure you want to delete this product?', //确定删除此条产品信息吗？
      Upper: 'Upper limit: 10 products', //限制10
      selectCP: 'Please select “Category” and “Product”', //当前分析未选择品类和产品
      selectCPs: 'Please select “Category” and “Product” for prediction', //当前分析未选择品类和产品
      add: 'Your new password cannot be the same as yourinitial password',
      Cancel: 'Cancel', //弹窗取消
      OK: 'OK', // 弹窗确认
      DeletsHint: 'The action cannot be undone', //删除后将无法恢复
      copySelect: 'Please select the product you want to copy!', //请勾选要复制的产品
    },
    loading: {
      suanfa: 'Processing...',
      tooltip: ' It takes ',
      minutes: 'about 4 minutes',
      Successsuanfa: 'Analysis Completed',
      downloading: 'Downloading',
      downloadcompletes: 'Download Completes',
      suanfas: '  This may take a while...',
      // tooltip:'  ',
      // minutes:'',
      // Successsuanfa:'',
      // downloading:'',
      // downloadcompletes:'',
    },
    Tooltip: {
      Clicking: 'Clicking the button will export the product data selected on the current page. ',
      export: 'If you want to export the results of all the products of this project,',
      enter: 'please enter “My Project-Existing Project” for export.  ',
      Importance: 'The importance degree represents the degree to which ',
      Impact: 'each sensory attributes has an impact on OAL. Grade 5',
      Hightest: 'means the highest importance and Grade 1 means the',
      low: 'lowest importance.',
      MarketSize: 'If the market size of the selected target consumer is small,  ',
      MarketSizes: 'The higher the prediction, the more accurate it is',
      Accuracy: 'Accuracy: 0-100% The higher the number,',
      higher: ' the more accurate the prediction. ',

      Difference: 'Difference: (Value of sensory attribute X of product)-(Value of sensory attribute X of selected product)',
      Title: '',
      P80: 'P80: The 80th percentile, exceeding this line means that this product exceeds the average likability of 80% of the products.',
      P60: 'P60: The 60th percentile, exceeding this line means that this product exceeds the average likability of 60% of the products.     ',
      P40: 'P40: The 40th percentile, exceeding this line means that this product exceeds the average likability of 40% of the products.    ',
      P20: 'P20: The 20th percentile, exceeding this line means that this product exceeds the average likability of 20% of the products.   ',
      information: 'For more information, please go to  ',
      Help: 'Help.',
    },
    HelpCenters: {
      CommonProblem: 'Common Problem',
      OperationalGuideline: 'Operational Guideline',
      Allvideos: 'All Videos',
      Operatevideo: 'Instructional Video',
      More: 'More',
      videoisminutes: '5 mins',
      Operationvideo: 'The instructional videos correspond to the selected guides.',
      Clickvideos: "Click 'More' to access all videos.",
      placeholder: 'Please fill in the detailed information, and you can also upload photos or attachments',
    },
    Footer: {
      address: '1201, North Tower, Huiyin Mansion , 2018 Huashan Road, Xuhui District, Shanghai, China',
    },
  },
  ...enLocale,
};

export default en;
