import zhLocale from 'element-ui/lib/locale/lang/zh-CN'; //引入element语言包
//需要转换的中文
const cn = {
  message: {
    info: {
      userinfo: '用户信息',
    },
    table: {
      item_number: '项目编号',
    },
    Home: {
      home: '首页',
      Title: '欢迎您使用HT-FAST平台',
      emailLogin: '邮箱账号登录',
      phoneLogin: '手机验证登录',
      forgetPwd: '忘记密码?',
      autoLogin: '15天自动登录',
      login: '登录',
      register: '立即注册',
      Cantcode: '收不到验证码?', //接收不到验证码
      //输入框提示信息
      Address: '请输入你的邮箱',
      Password: '请输入你的密码',
      mobile: '请输入11位手机号码', //手机号
      verify: '请输入 6 位有效验证码', //验证码
      Verification: '发送验证码',
      Resend: '后重发', //倒计时
      Security: '请先完成滑块验证', //请先完成滑块验证
      slider: '向右拖动滑块填充拼图',

      //错误提示
      emailError: '邮箱账号不正确，请重新输入！',
      passwordError: '密码不正确，请重新输入！',
      passwwordNot: '邮箱账号未注册，请重新输入！',

      //注册
      Register: '立即注册',
      QR: '请扫描上方二维码，联系灏图工作人员进行新账号注册',
      //联系方式
      Contact: '联系方式',
      QRcode: '请扫描上方二维码，联系灏图工作人员进行相关操作',
      //  找回密码
      retrieve: {
        // 第一页
        ConfirmAccount: '确认账号',
        Return: '上一步',
        EnterYour: '输入本次找回密码的邮箱账号',
        EmailAccount: 'Your Email Account',
        TryAgain: '账号不正确，请重新输入！',
        NextStep: '下一步',
        // 第二页
        Verification: '验证方式',
        SentTo: 'An email with verification will be sent to ', //show
        verifycode: '请输入 6 位有效验证码',
        Sendcode: '发送验证码',
        Back: '上一步',
        ContactUs: '若无法验证,您可以联系灏图', //show
        ErrorVerify: '验证码错误或失效,请重新获取!',
        ErrorTip: '设置新密码不符合要求，请重新设置',
        ErrorTips: '请二次输入确认新的登录密码',

        //第三页
        NewPwd: '设置新密码',
        setNew: '请设置', //show
      },
    },
    Header: {
      one: '主页',
      two: '我的项目',
      three: '产品预测',
      four: '品类分析',
      five: '历史数据比较',
      six: '帮助中心',
      //二级菜单
      //单品分析
      single: '单品分析',
      compare: '对比分析',
      //品类分析:
      profile: '产品轮廓',
      classification: '产品分类',
      Feature: '特征分析',
      significance: '重要度分析',
      //修改密码
      ResetPassword: '修改密码',
      OldPassword: '旧密码: ',
      NewPassword: '新密码: ',
      Confirmpassword: '确认密码: ',
      EnterOldPWD: '请输入旧密码',
      EnterNewPWD: '请输入新密码',
      EnterNewPWDs: '请再次输入新密码',
      passwordincorrect: '旧密码错误',
      Passwordmust: ' 输入密码要包含英文字母、数字、字符',
      Passwordsmatch: '两次输入密码不相同',
      Change: '修改密码',
      Switch: '切换账号',
      Log: '退出登录',
    },
    Project: {
      //一级导航栏
      one: '我的项目',
      two: '新建项目',
      three: '已有项目',
      four: '草稿箱',
      //修改初始密码
      Reset: '修改初始密码',
      safety: '为了您的账号安全，首次登录请修改初始密码',
      New: '新密码:',
      Confirm: '确认密码: ',
      Enter: '请输入新密码',
      Confirms: '请再次输入新密码',
      successfully: '密码修改成功',
      // ReturnTo:"Return to login page in",
      // ReturnTos:"seconds",
      messages: '更改',
      //项目首页-----------
      Fast: '快速访问', //快速访问
      recently: '最近',
      recentlyOpne: '最近打开',
      number: '项目编号',
      CreationTime: '创建时间',
      QuantityProduct: '产品数量',
      ProductName: '产品名称',
      Operation: '操作',

      empty: '暂无最近打开记录哦', //空数据
      //项目操作项

      //项目共用操作
      view: '查看',
      edit: '编辑',
      forecast: '预测',
      derive: '导出',
      remove: '移除',
      delete: '删除',
      Cancel: '取消',
      OK: '确定',
      Remove: '是否从最近列表中移除？',
      Removeit: '  移除后，该项目在最近列表中将不可见',
      SaveAs: '另存为',
      Reports: '您确定重新上传项目文件?',
      ReDetail: '重新上传后,该项目当前数据将被上传覆盖',
      AsProjectNo: '另存为项目编号',
      onlyOne: '请选择仅一条产品信息进行复制.',
      SaveAS: '另存为成功!',
      NotCopy: '非编辑模式不可复制',
      NotAdd: '非编辑状态无法新增产品',
      //新建/修改下拉选择
      Select2: '请选择产品来源',
      None: '无',
      Market: '市售产品',
      Prototype: '开发原型',
      Benchmark: '竞争品牌',
      //--产品性状
      Cream: '膏霜',
      Lotion: '乳液',
      Gel: '啫喱',
      Oil: '油类',
      Other: '其他',
      Smearmask: '涂抹式面膜',
      Sheetmask: '贴片式面膜',
      Toner: '水类',
      Select: '请选择产品性状',
      //--物理化学防晒
      Chemical: '化学',
      Physical: '物理',
      Hybird: '物化结合',
      Select3: '请选择防晒分类',
      // 新建项目----------------------

      new: {
        SaveD: '产品保存成功 ',
        informationError: '产品信息填写有误,请进行修改 ',
        NewProject: '新建项目',
        Choosefile: '将填写完成的模板文件拖到此处',
        downLoad: '如果您是首次使用，请先下载FAST模板',
        Import: '导入',
        Cancel: '删除',
        Save: '保存',
        Edit: '编辑',
        Importhint: '导入新文件进行覆盖',
        Category: '产品品类',
        Suncare: '防晒霜', //防晒霜
        Copy: '复制',
        Delete: '删除',
        SensoryData: '感官数据',
        Loaded: '已载入',
        InstrumentalData: '仪器数据',
        ComingSoon: '即将上线',
        CancelEdit: '取消编辑',
        Editing: '编辑中',
        Create: '完成',
        Null: '未载入',
        Unselected: '未选择',
        auto: '自动保存至草稿箱',
        Saving: '正在自动保存草稿中',
        Saved: '已自动保存至草稿箱',
        DeleteThis: '确定删除此条产品信息吗?',
        ProjectNo: '项目编号',
        ImportTip: '导入新文件进行覆盖', //带入新闻你
        router: '是否跳转到其他页面？',
        routerContent: '您的产品信息正处于编辑状态，建议保存或取消编辑',
      },
      check: {
        Download: '下载',
      },
      //已有项目----------
      HaveTitle: '已有项目',

      //已有项目和草稿箱的搜索翻译
      Item: '项目编号：',
      information: '产品信息：',
      CreationTimes: '创建时间：', //创建时间
      allExport: 'Export', //多选导出
      allDelete: 'Delete', //多选删除
      start: '开始时间',
      To: '至',
      end: '结束时间',
      Export: '批量导出', //批量导出
      Delete: '批量删除', //批量删除
      ActionUndone: '删除后将无法恢复',
      //表头信息--------
      item: '项目编号', //项目编号
      creationTimes: '创建时间', //创建时间
      category: '产品品类', //品类
      quantity: '产品数量', //产品数量
      productName: '产品名称', //产品名称
      operation: '操作', //操作
      //草稿箱----------
      draftTitle: '草稿箱',
      editing: '继续编辑', //草稿箱的项目操作
      NotSaved: '您的产品信息还未保存！',
      PleaseSave: '返回时请保证产品信息处于保存状态',
      emptyDraft: '暂时还没有草稿保存哦',
      NoResults: '搜索结果为空', //搜索为空
      NoProjects: '暂时还没有项目哦',
      // 页面展示
      selected: 'projects selected', //已选择
      total: 'in total', //共
      found: 'projects found', //搜索到
    },
    itemAnalyse: {
      //标题和选择框
      Title: '单品分析',
      //Select
      item: '选择项目',
      products: '选择产品',
      population: '目标人群',
      select: '点击下拉选择',
      select1: '项目',
      select2: '产品',

      //感官属性box1----
      box1Title: '感官属性', //感官属性
      Importance: '重要度', //重要度
      inputContent: '请输入或选择展示的属性名称',
      Sensorydata: '感官数据',
      //content
      all: 'All',
      Sensory: '感官数据', //感官数据
      //消费者指标预测 box2------
      box2Title: '消费者指标预测', //消费者指标预测
      explain: '通过感官数据测量', //通过感官数据测量
      section95: '95%置信区间：', //95置信区间
      // section90:"90%置信区间:",//90置信区间
      section90: '5分制评价', //90置信区间
      Predicted: '整体喜好度预测值', //整体喜好度预测值
      percentage: '预测精准度百分比', //预测精准度百分比

      Overall: '整体喜好度', //整体喜好度
      Comprehensive: '综合性指标', //综合性指标
      Preference: '喜好度指标', //喜好度指标
      predicted: '喜好度', //喜好度
      null: '暂无', //暂无
      accuracy: '预测精准度', //精准度
      barChat: '柱状图', //柱状图
      Rainbow: '彩虹图', //彩虹图
      OverallAttributes: '综合性指标',
      SpecifiaAttributes: '特征指标 ',
      //选择对比产品 box3-------
      box3Title: '选择对比产品', //选择对比产品
      maximum: '选择上限为4个', //选择上限
      Recommended: '推荐优化方案', //推荐优化方案
      add: '添加到项目', //添加到项目
      Norecommended: '暂无推荐方案', //暂无推荐方案(空)
      Successfully: '添加优化方案到项目中成功',

      product: '产品名称', //产品
      liking: '整体喜好度', //整体喜好度
      Appeara: '产品外观', //产品外观
      Overallus: '使用后的肤感', //使用后的肤感
      Absorpti: '产品质地', //产品质地

      Historical: '历史产品选择', //历史产品选择
      createTime: '创建日期', //创建日期
      number: '项目编号', //项目编号
      name: '产品名称', //产品名称
      ocerallPredicted: '整体喜好度', //整体喜好度
      NoHistorical: '暂无历史产品', //暂无历史产品(空)
      optimal: '优化方案',
      //彩虹图的信息框
      productName: '名称：',
      Values: '值：',
      TargetConsumers: '目标人群：',
      //  柱状图
      konw: '了解',
      Population: '目标人群的模型精度有所降低，预测结果仅供参考', //!目标人群
      ProductNume: '产品名称',
      Note: '提示',
      analyse: '请您先进行分析', //!请您先进行分析
      noadd: '请勿重复添加', //! 请勿重复添加
      nohandle: '请勿重复操作', //!请勿重复操作
      cilckProject: '点击将选择的推荐产品',
      cilckProject2: '添加到当前项目中',
      manyProduct: '对比产品已达选择上限，请取消单个或多个选框重新选择',
    },
    Contrast: {
      Title: '对比分析', //对比分析
      //Select
      item: '选择项目',
      products: '选择产品',
      compare: '选择对比产品',
      select: '项目',
      select1: '产品',
      select2: '点击下拉选择',
      //content---box1---------
      box1Title: '感官属性数据', //感官属性数据
      Importance: '重要度', //重要度
      AttributeName: '属性名称', //  属性名称
      AttributeData: '属性数据', //  属性数据

      NoAttribute: '暂无属性数据', //暂无属性数据(空)

      //box2----
      box2Title: '对比分析结果', //对比分析结果
      Difference: '差值', //差值
      NoResult: '暂无分析结果', //暂无分析结果
    },
    Category: {
      // 共同
      common: {
        Category: '选择品类',
        Products: '选择分析产品',
        Description: '图表描述',
        Hide: '隐藏',
        Show: '显示',
        empty: '暂时还没有图表哦',
        DownloadGraph: '单张下载', //
        DownloadAll: '全部下载', //
        Select: '下拉选择产品',
      },
      // 产品轮廓-----------------------start
      page1: {
        Title: '产品轮廓',

        // content
        empty: '暂时还没有图表哦',
        Range: '产品信息显示',
      },
      // 产品分类-----------------------start
      page2: {
        Title: '产品分类',

        // content
        Label: '产品标签',
        SPF: 'SPF数值',
        none: '暂无',
        Formulation: '防晒分类',
        Physical: '物理',
        Chemical: '化学',
        Hybrid: '物化结合',
        empty: '暂时还没有图表哦',
      },

      // 特征分析-----------------------start
      page3: {
        Title: '特征分析',
        Factor: '坐标选择',

        Select: '请选择坐标',
        PCA: '主成分分析',
        Rotated: '因素旋转分析',
        Clustering: '算法分类',
        SPFValues: 'SPF数值',
        FormulationSystem: '防晒分类',
        None: '默认',
        Clusters4: '4类',
        Clusters6: '6类',
        Clusters8: '8类',
        Clusters12: '12类',
        Legend: '图例显示', //图例显示"
        detail: '离开后，当前分析内容将不会作保留，需重新分析',
      },
      // 重要度分析-----------------------start
      page4: {
        title: '重要度分析',
        Axis: '选择坐标',
        Select: '请选择坐标',
        Selects: '点击下拉选择坐标',
        SelectOne: '点击下拉选择品类',
        Attributes: '坐标选择', //
        ChartType: '图表类型', //
        HeatMap: '热力图', //热力图
        Network: '网络图', //网络图
      },
    },

    History: {
      Select: '项目', //项目
      Select1: '点击下拉选择', //点击下拉选择
      Category: '选择品类', //品类
      Products: '选择对比产品', //产品
      Sensory: '感官属性比较',
      Predicted: '喜好度预测值比较',
      Scale: '刻度数字', //显示刻度
      Percentile: '百分位线', //百分位线
      empty: '暂时还没有图表哦', //暂时还没有图表哦
      limit: '最多只能选择30个对比产品哦！',
      limits: '最多只能选择8个对比产品哦！',
      Comparison: '请选择对比产品 ',
      NoData: '暂时还没有内容哦',
      Min: '最小限制数量为3个!',
      SensoryBar: {},
    },
    Help: {
      Title: '帮助中心',
      Contact: '联系我们',
      Question: '常见问题',
      Lines: '百分位线是什么?',
      BackSee: '我要反馈',
      Name: '客户名称',
      Company: '公司名称',
      YourEmail: '联系方式',
      Questions: '问题描述',
      detail: '请填写详细内容,您也可以上传照片或附件',
      Send: '发送',
      Names: '请填写您的名称',
      Companys: '请填写公司名称',
      Email: '请填写您的邮箱账号',
      Question2: '由于数值进位，所以有时会发生数值相同但条形长度不同的情况。这并非故障，请放心使用。',
    },
    Message: {
      NoSelected: '请先选择完整分析信息!',

      phoneNumber: '手机号码不正确，请重新输入! ', //手机号不正确，请重新输入；
      Verification: '验证码未发送，请点击发送！', //验证码未发送，请点击发送
      accountNot: '手机号码未注册，请先注册！', //手机号码未注册，请先注册！
      codeError: '验证码不正确，请重新输入！.', //验证码不正确，请重新输入
      unfinished: '因素旋转正在分析中，请稍等...',
      inished: '因素旋转分析完成',
      notRegistered: 'The account is not registered!', //该账号未注册
      Login: '登录成功', //登录成功
      Saved: '保存成功', //保存成功
      Oldpassword: '旧密码错误', //旧密码错误
      export: '请选择一条以上的产品信息进行批量导出', //请选择一条以上的产品信息进行批量导出
      delete: '请选择一条以上的产品信息进行批量删除', //请选择一条以上的产品信息进行批量删除
      Passwordmust: '输入密码要包含英文字母,数字,字符 ', //输入密码要包含英文字母,数字,字符
      Passwords: '两次输入密码不相同', ////两次输入密码不相同
      Import: '导入成功!', //导入成功!
      ProjectNo: '项目号重复,请修改后在保存! ', //项目名重复,请修改后在保存
      Atleast: '请选择勾选至少一条产品信息进行删除操作 ', //请选择勾选至少一条产品信息进行删除操作,
      emptyNo: '项目号不可为空或已重复，请修改后再次上传', //项目编号不可为空或已重复,请修改后再次上传
      emptyNo1: '项目内产品品类不一致，请修改后再次上传',
      Batchoperation: '请选择至少一条以上的项目进行批量操作', //请选择至少一条以上的项目进行批量操作
      notSave: '您还有未保存的产品信息!', //您的产品信息还未保存
      leaveSave: '返回时请保证产品信息处于保存状态', //返回时请保证产品信息处于保存状态
      Missing: '您还有未填写选择的信息!', //您还有未填写选择的信息
      FIELDS: '带*号为必填或必选项', //带*号为必填
      //  confirmDelete:"Are you sure you want to delete “”? ",//确定删除*** 项目吗？
      undone: '删除后将无法恢复', //删除后将无法恢复
      re_upload: '您确定重新上传项目文件?', //您确定重新上传项目文件？
      overRite: '重新上传后，该项目当前数据将被上传覆盖', //重新上传后，该项目当前数据将被上传覆盖
      confirmDeletes: '确定删除此条产品信息吗？', //确定删除此条产品信息吗？
      Upper: '最多只能选择10个对比产品哦!', //限制10
      selectCP: '当前分析未选择品类和产品', //当前分析未选择品类和产品
      selectCPs: '当前分析未选择品类和产品，请选择后进行添加', //当前分析未选择品类和产品
      add: '新密码不能与初始密码相同',

      // ------第四版
      comparison: 'Please choose product(s) for comparison.', //请选择对比产品
      copySelect: '请勾选要复制的产品!', //请勾选要复制的产品
      ProjectN: 'Project No.', //项目编号
      added: 'Successfully Added', //添加优化方案到项目中成功
      UpperLimit: 'You have reached upper limit of selection.', //对比产品已选择上线
      edit: 'Incorrect product information, please edit.', //产品信息填写有无，请进行修改。
      remove: 'Remove it from “Latest Application” ', //移除后，该项目在最近列表中将不可见
      marked: 'Fields marked with an * are required', //带*号的内容为必填项，请填写完整
      addressError: 'Invalid email address, try again.', //邮箱账号格式错误，请重新填写
      Percentile: 'Percentile Lines', //百分位线
      Cancel: '取消', // 弹窗取消按钮
      OK: '确定', // 弹窗确认按钮
      DeletsHint: '删除后将无法恢复', //删除后将无法恢复
    },
    loading: {
      suanfa: '算法生成中',
      tooltip: '预计需要',
      minutes: '4分钟',
      Successsuanfa: '算法生成完成',
      downloading: '下载中',
      downloadcompletes: '下载完成',
      suanfas: '可能需要一些时间...',
      // tooltip: '',
      // minutes: '',
      // Successsuanfa: '',
      // downloading: '',
      // downloadcompletes: '',
    },
    Tooltip: {
      Clicking: ' 点击按钮将导出当前页面选择的产品数据',
      export: '若导出项目全部产品结果',
      enter: '请进入我的项目-已有项目进行导出',
      Importance: '每个感官属性对整体喜好度',
      Impact: '的影响程度。分五级，分值',
      Hightest: '越大重要度越高，分值越小',
      low: '重要度越低。',
      MarketSize: '若选择的目标人群market size较小时，',
      MarketSizes: '越高代表预测越精准。',
      Accuracy: '预测精准度取值0-100%，',
      higher: '越高代表预测越精准。',
      Difference: '差值：（对比产品的感官属性X的值）-（选择产品的感官属性X的值）。',
      Title: '百分位线数据从小到大排序,累计百分位',
      P80: 'P80：第80%分位数,表示超过了80%的产品的喜好度平均值',
      P60: ' P60：第60%分位数,表示超过了60%的产品的喜好度平均值',
      P40: '  P40：第40%分位数,表示超过了40%的产品的喜好度平均值',
      P20: ' P20：第20%分位数,表示超过了20%的产品的喜好度平均值',
      information: '更多详细内容详见',
      Help: '帮助中心',
    },
    HelpCenters: {
      CommonProblem: '常见问题',
      OperationalGuideline: '操作指引',
      Allvideos: '全部视频',
      Operatevideo: '操作视频',
      More: '更多',
      videoisminutes: '视频共五分钟',
      Operationvideo: '操作视频与选择的指引相呼应',
      Clickvideos: '点击更多跳转全部视频',
      placeholder: '请填写详细内容，您也可以上传照片或附件',
    },
    Footer: {
      address: '办公地址：上海市徐汇区华山路2018号汇银广场北楼1201-1室',
    },
  },
  ...zhLocale,
};

export default cn;
