//  store.js 中都mutation中增加添加和删除token的方法
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
  // 全局管理的数据存储
  isLogin: '0',
  ser: null,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '', // token
  sessionToken: sessionStorage.getItem('sessionToken') ? sessionStorage.getItem('sessionToken') : '',
  userid: localStorage.getItem('userid') ? localStorage.getItem('userid') : '',
  componentName: 'panel', // like:喜好度  pannel:感官属性
  forcast_data: [], //喜好度分析的数据
  attribute_data: [],
  radarRole: false, // false柱状图 true 雷达图
  panel_data: [], // 感官属性数据
  productNameList: [], // 感官属性对比产品名称
  panelNameList_en: [], //感官属性对比产品英文名称
  panelNameList: [], //感官属性维度
  likeNameList: [], //喜好度属性维度
  likeNameList_en: [], // 英文喜好度属性名字
  likeProductList: [], //喜好度对比产品的名称
  showLine: false,
  line_num: false, //显示刻度数字
  cancelTokenArr: [], // 取消请求token数组
  // 我的项目数据
  overTimeToken: true, //超时登录回到首页,拿到此数据,判断登录按钮.
  showProgram: [], //我的项目,展示的数据.
  backFrom: 0, //左上角的返回路径判断
  upload: false, //新建项目导入组件的显示
  lang: 'cn', //中英文切换.
  changeRoute: true, //判断是否显示离开路由弹窗
};
export default new Vuex.Store({
  state,
  getters: {
    // 监听数据变化的
    getStorage(state) {
      // 获取本地存储的登录信息
      if (!state.token) {
        state.token = JSON.parse(localStorage.getItem('token'));
      }
      return state.token;
    },
    getSessionToken(state) {
      if (!state.sessionToken) {
        state.sessionToken = JSON.parse(sessionStorage.getItem('sessionToken'));
      }
      return state.sessionToken;
    },
    getStorage_id(state) {
      // 获取本地存储的登录信息
      if (!state.userid) {
        state.userid = JSON.parse(localStorage.getItem('userid'));
      }
      return state.userid;
    },
    getForcast_data() {
      return state.forcast_data;
    },
    getAttribute_data() {
      return state.attribute_data;
    },
  },
  mutations: {
    $_setChangeRoute(state, value) {
      state.changeRoute = value;
      // sessionStorage.setItem('forcast', value)
    },
    // 请求的数据放里面
    pushToken(state, payload) {
      state.cancelTokenArr.push(payload.cancelToken);
      // console.log("请求的数据放里面",payload.cancelToken);
    },
    // 清除所有接口请求
    clearToken({ cancelTokenArr }) {
      cancelTokenArr.forEach((item) => {
        item('路由跳转取消请求');
        // console.log("清除所有接口请求","清除所有接口请求");
      });
      cancelTokenArr = [];
    },
    $_setToken(state, value) {
      // 设置存储token
      state.token = value;
      localStorage.setItem('token', value);
    },
    $_setUserid(state, value) {
      // 设置存储token
      state.userid = value;
      localStorage.setItem('userid', value);
    },
    $_removeStorage() {
      // 删除token
      localStorage.removeItem('token');
    },
    //记录组件切换
    $_changeComponentName(state, value) {
      state.componentName = value;
    },
    $_setSessinoToken(state, value) {
      state.sessionToken = value;
      sessionStorage.setItem('sessionToken', value);
    },
    $_setForcast_data(state, value) {
      state.forcast_data = value;
      // sessionStorage.setItem('forcast', value)
    },
    $_setAttribute_data(state, value) {
      state.attribute_data = value;
      // sessionStorage.setItem('forcast', value)
    },
    $_changradarRole(state, value) {
      state.radarRole = value;
    },
    $_changePanel_data(state, value) {
      state.panel_data = value;
    },
    $_changeProductNameList(state, value) {
      state.productNameList = value;
    },
    $_changePanelNameList(state, value) {
      let name = value;
      for (let index in value) {
        if (name[index].indexOf('(') !== -1 && name[index].indexOf(')') == -1) {
          name[index] += ')';
        }
        name[index];
      }
      state.panelNameList = value;
    },
    $_changePanelNameList_en(state, value) {
      let name = value;
      for (let index in value) {
        if (name[index].indexOf('(') !== -1 && name[index].indexOf(')') == -1) {
          name[index] += ')';
        }
        name[index];
      }
      state.panelNameList_en = value;
    },
    $_changeLikeNameList(state, value) {
      state.likeNameList = value;
    },
    $_changeLikeNameList_en(state, value) {
      state.likeNameList_en = value;
    },
    $_changeLikeProductList(state, value) {
      state.likeProductList = value;
    },
    $_changeShowLine(state, value) {
      state.showLine = value;
    },
    $_changeLine_num(state, value) {
      state.line_num = value;
    },
    $_addShowProgram(state, value) {
      state.showProgram = value;
    },
    $_change_overTimeToken(state, value) {
      state.overTimeToken = value;
    },
    $_change_backFrom(state, value) {
      state.backFrom = value;
    },
    $_change_upload(state, value) {
      state.upload = value;
    },

    $_changeLang(state, value) {
      state.lang = value;
    },
  },
});
