//rem.js
const baseSize = 16
// 设置 rem 函数
function setRem() {

    var system = {
        win: false,
        mac: false,
        xll: false,
    };
    var p = navigator.platform;
    console.log(p)
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.x11 = p == "X11" || p.indexOf("Linux") == 0;
    let clientWidth
    if (system.win || system.mac || system.xll) {
        clientWidth = document.documentElement.clientWidth
        if (clientWidth < 1280) {
            clientWidth = 1280
            console.log("1280");
        } else 
        if (clientWidth > 1920) {
            // clientWidth = 1920
            console.log("1920");
        }
    } else {
        //跳转语句，如果是手机访问就自动跳转到手机页面
        clientWidth = document.documentElement.clientWidth
        console.log('手机2')
    }


    // 当前页面宽度相对于 1440宽的缩放比例，可根据设计图需要修改。
    const scale = clientWidth / 1920
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}
