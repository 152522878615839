// 引入路由模块 挂载使用哦
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import store from '@/store'
// import { HttpClient } from "@/utils/http";
// import store from "@/store/index"
const routes = [{
    path: '/home',
    name: "home",
    component: () => import('../pages/Home/Home.vue'),
},

{
    path: '/myproject',
    name: "myproject",
    component: () => import('../pages/Project/MyProject.vue'),
    meta: {
        index: 1
    }

},

{
    path: '/changeproject',
    name: 'changeproject',
    component: () => import('../pages/Project/ChangeProject.vue')
},

{
    path: '/createproject',
    component: () => import('../pages/Project/CreateProject.vue'),

},
{
    path: '/mydrafts',
    component: () => import('../pages/Project/MyDrafts.vue'),

},



{
    path: '/header',
    component: () => import('../components/Header.vue'),

},
{
    path: '/',
    redirect: '/home',
    component: () => import('../pages/Home/Home.vue'),
},

{
    path: '/itemanalysis',
    name: "itemanalysis",
    component: () => import('../pages/ItemAnalysis/index.vue'),
    meta: {
        index: 2
    }
},
{
    path: '/Acategory',
    name: "Acategory",
    component: () => import("../pages/Acategory/Acategory.vue"),
    meta: {
        index: 3
    }
},
{
    path: '/RoughSketch',
    name: "roughSketch",
    component: () => import("../pages/Acategory/RoughSketch.vue"),
    meta: {
        index: 8
    }
},
{
    path: '/importanceAnalysis',
    name: "importanceAnalysis",
    component: () => import("../pages/Acategory/importanceAnalysis.vue"),
    meta: {
        index: 7
    }
},
{
    path: '/Characteristic',
    name: "characteristic",
    component: () => import("../pages/Acategory/Characteristic.vue"),
    meta: {
        index: 4
    },
 
},
{
    path: '/contrast',
    name: "contrast",
    component: () => import('../pages/Contrast/index.vue'),
    // meta: {
    //     index: 3
    // }
},
{
    path: '/history',
    name: "history",
    component: () => import('../pages/History/index.vue'),
    meta: {
        index: 4
    }
},

{
    path: '/forecast',
    component: () => import('../pages/History/forecast.vue'),

},
{
    path: '/attribute',
    component: () => import('../pages/History/attribute.vue'),

},


{
    path: '/radar3',
    component: () => import('../pages/History/radar3.vue'),

},

{
    path: '/radar_fist',
    component: () => import('../pages/History/radar_fist.vue'),

},

{
    path: '/news',
    name: "news",
    component: () => import('../pages/Home/News.vue'),

},



{
    path: '/dosearch',

    component: () => import('../pages/History/DoSeach.vue'),

},












]


const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    // scrollBehavior: () => ({ y: 0 }),//解决 跳转后不是顶部的问题

    routes
})
// //全局前置守卫：初始化时执行、每次路由切换前执行
// router.beforeEach((to, from, next) => {
//     console.log('beforeEach', to)
//     console.log('beforeEach', from)
//     HttpClient.post("subuser/tokenverify", {
//         token: store.state.token
//     }).then((res) => {

//         if (res.status == 1) {

//             let userInfo = {
//                 isLogin: false,
//             };

//             localStorage.setItem("userInfo", JSON.stringify(userInfo));


//         }
//     })
//     // next()
//     if (to.path != "/home") {
//         // next()
//         let log = JSON.parse(localStorage.getItem("userInfo"))
//         // console.log(log.isLogin)
//         // console.log(JSON.parse(localStorage.getItem("userInfo")));
//         // console.log(JSON.parse(localStorage.getItem("oooo")));
//         if (log.isLogin == true) {   //权限控制的具体规则
//             next()//放行
//         } else {
//             alert('请重新登录')
//             next(
//                 '/home'
//             )
//             localStorage.removeItem("userid")
//             //next({name:'guanyu'})
//         }
//     } else {
//         next()
//     }


// })



// src/router/index.js


//路由前置守卫，每一次页面跳转要做的事情
router.beforeEach(function (to, from, next) {
    store.commit('clearToken') // 取消请求
    next()
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}



export default router