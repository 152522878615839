function quit() {

    // let language= localStorage.getItem('lang')
    // let language=this.$store.state.lang;
    this.is_login = 1;
    this.is_logins = 0;
    this.email_val = "";
    this.password_val = "";
    // localStorage.clear();
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userid')
    localStorage.removeItem('user_name')
    localStorage.removeItem('user_email')
    localStorage.removeItem('token')
    // localStorage.setItem('lang',language)
    
    console.log("跳转页面！退出成功！");
}


export default {
    quit
}