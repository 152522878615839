import Vue from 'vue'
import App from './App.vue'
//引入vuex 库
import store from './store'
// Vue.forceUpdate()

// 引入ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
// 引入路由
import router from '../src/router/index'
Vue.config.productionTip = false

// 滑动验证插件
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

// 引入字体
import '@/assets/fonts/fonts.css'

// 引入自适应插件
//main.js
import '@/utils/rem'

// 引入退出方法
import Quit_id from '@/utils/quit'
Vue.prototype.Quit_id = Quit_id;
// import "amfe-flexible/index.js";

// /*引入axios插件*/
// import axios from 'axios'
// Vue.prototype.$http = axios;

// 设置中央事件总线
Vue.prototype.$EventBus = new Vue();


//引入挂在翻译插件
import i18n from './i18n/i18n';

// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

//引入视频插件
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer);






// --------------------------------
new Vue({
  render: h => h(App),
  store,
  router,
  i18n
}).$mount('#app')
