<template>
  <div id="app">
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
export default {
  name: 'App',
  components: { Footer },
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕尺寸
    };
  },
  mounted() {
    // const that = this
    // window.onresize = () => {
    //   return (() => {
    //     window.screenWidth = document.body.clientWidth
    //     that.screenWidth = window.screenWidth
    //   })()
    // }
    // console.log(that.screenWidth)
  },
  // created() {
  //   //在页面刷新时将vuex里的信息保存到localStorage里

  //   window.addEventListener("beforeunload", () => {
  //     localStorage.setItem("messageStore", JSON.stringify(this.$store.state));
  //   });

  //   //在页面加载时读取localStorage里的状态信息

  //   localStorage.getItem("messageStore") &&
  //     this.$store.replaceState(
  //       Object.assign(
  //         this.$store.state,
  //         JSON.parse(localStorage.getItem("messageStore"))
  //       )
  //     );
  // },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}
*/
/* 隐藏滚动条

/* body::-webkit-scrollbar {
  display: none;
} */
/*滚动条整体样式*/
.body::-webkit-scrollbar {
  width: 0.1px;
  height: 0.1px;
  border-radius: 3px;
  margin-left: 16px;
}

/*滚动条整体样式*/
body::-webkit-scrollbar {
  width: 9px;
  height: 8px;
  border-radius: 3px;
  margin-left: 16px;
}

/*滚动条里面轨道*/
/* body::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 2px;
} */

/*滚动条里面小方块*/
body::-webkit-scrollbar-thumb {
  background: #2e5ca1;
  border-radius: 10px;
}

/* body::-webkit-scrollbar-corner {
  background: #2581d7b8;
  height: 10px;
} */
.el-message__content {
  font-size: 20px;
}
</style>

<style lang="less">
@import url('../src/style/tootip.less');
</style>
